.guustav-shepherd .shepherd-header {
  background: #483D8B!important;
}
.guustav-shepherd .shepherd-button-primary {
  background: #483D8B!important;
}
.guustav-shepherd .shepherd-header h3 {
  color: white!important;
}
.guustav-shepherd .shepherd-text {
  background: white;
}
.guustav-shepherd .shepherd-footer {
  background: white;
}
.guustav-shepherd .shepherd-arrow:before {
  background: white!important;
  border: 1px solid #ddd!important;
}
.guustav-shepherd .shepherd-cancel-icon {
  background: #483D8B!important; 
}
.guustav-shepherd .shepherd-cancel-icon span {
  color: white!important;
}
/* Not used right now - from create-react-app */
/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/
